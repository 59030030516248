import { Component } from "react";
import AppContext from "../../AppContext";
import Datatable from "../componentes/Datatable";
import ProtocoloForm from "./ProtocoloForm";
import ProtocoloAndamentos from "./ProtocoloAndamentos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Container, Row } from "react-bootstrap";

class ProtocoloList extends Component {

    static contextType = AppContext;

    state = {
        dashboard: [],
        classe: '',
        filters: []
    }

    onAction(action, callback) {

        const myCallback = () => {
            this.update();
            callback();
        }

        switch (action.name) {
            case "Ver Detalhes":
                this.context.request("GET", "/protocolos/" + action.id).then(protocolo =>
                    this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.context.setContent(<ProtocoloList />)} />)
                );
                break;
            case "Fiscalizar":
                this.context.request("GET", "/protocolos/" + action.id).then(async protocolo => {
                    protocolo.Perguntas = (await this.context.request(`GET`, `/protocolos/${protocolo.id}/perguntas`)).map(pergunta => pergunta.id);
                    protocolo.VinculosInstrutores = (await this.context.request(`GET`, `/protocolos/${protocolo.id}/vinculos/instrutores`)).map(vinculo => vinculo.id);
                    protocolo.ProtocoloOriginalId = protocolo.id;
                    protocolo.id = null;
                    protocolo.tipo = `Fiscalização`;
                    protocolo.status = null;
                    this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.context.setContent(<ProtocoloList />)} />);
                });
                break;
            case "Renovar":
                this.context.request("GET", `/cadastros/${action.CadastroId}/debito`).then(({ debito }) => {
                    if (debito) {
                        window.alert("O cadastro possui débitos pendentes relacionados a multa. Regularize as pendências antes de prosseguir com um novo protocolo.")
                    } else {
                        this.context.request("GET", "/protocolos/" + action.id).then(async protocolo => {
                            protocolo.Perguntas = (await this.context.request(`GET`, `/protocolos/${protocolo.id}/perguntas`)).map(pergunta => pergunta.id);
                            protocolo.VinculosInstrutores = (await this.context.request(`GET`, `/protocolos/${protocolo.id}/vinculos/instrutores`)).map(vinculo => vinculo.id);
                            protocolo.ProtocoloOriginalId = protocolo.id;
                            protocolo.id = null;
                            switch (protocolo.tipo) {
                                case "Licenciamento":
                                case "Renovação de Licenciamento":
                                    protocolo.tipo = "Renovação de Licenciamento";
                                    break;
                                case "Credenciamento":
                                case "Renovação de Credenciamento":
                                    protocolo.tipo = "Renovação de Credenciamento";
                                    break;
                                default:
                                    protocolo.tipo = "";
                                    break;
                            }
                            protocolo.status = null;
                            this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.context.setContent(<ProtocoloList />)} />);
                        });
                    }
                });
                break;
            case "Andamentos":
                this.context.request("GET", "/protocolos/" + action.id + "/andamentos").then(andamentos =>
                    this.context.openModal({
                        params: { size: "xl" }, title: "Andamentos", body: <ProtocoloAndamentos ProtocoloId={action.id} andamentos={andamentos} onExit={() => {
                            this.context.closeModal();
                        }} />
                    })
                );
                break;
            case "Arquivar":
                this.context.request("PATCH", "/protocolos/" + action.id + "/arquivar").then(protocolo => {
                    this.context.addToast({ header: "Sucesso", body: "Ação realizada com sucesso!" })
                    myCallback();
                });
                break;
            case "Desarquivar":
                this.context.request("PATCH", "/protocolos/" + action.id + "/desarquivar").then(protocolo => {
                    this.context.addToast({ header: "Sucesso", body: "Ação realizada com sucesso!" })
                    myCallback();
                });
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/protocolos/" + action.id).finally(myCallback);
                break;
            default:
                break;
        }
    }

    render() {
        return <>
            <Container>
                <h2><FontAwesomeIcon icon={faFile} /> Pesquisa de Protocolos</h2>
                {this.state.filters.length? <h4><i>{this.state.filters.map(f => `${f.label}: ${f.value}`).join(', ')}</i></h4> : ""}
                {this.context.usuario.classe === "usuario" && <Row>
                    <Col lg={4} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: '' })} role="button" bg="dark" className={`flex-grow-1 text-white card-dashboard ${this.state.classe ? null : `card-dashboard-active`}`}>
                            <Card.Header>Todos os Protocolos</Card.Header>
                        </Card>
                    </Col>
                    <Col lg={2} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: "arquivo" })} role="button" bg="success" className={`flex-grow-1 text-white card-dashboard ${this.state.classe === "arquivo" ? `card-dashboard-active` : ``}`}>
                            <Card.Header className="text-center">Protocolos Finalizados</Card.Header>
                        </Card>
                    </Col>
                    <Col lg={2} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: "usuario" })} role="button" bg="warning" className={`flex-grow-1 text-white card-dashboard ${this.state.classe === "usuario" ? `card-dashboard-active` : ``}`}>
                            <Card.Header className="text-center">Designados ao CBMPA</Card.Header>
                        </Card>
                    </Col>
                    <Col lg={2} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: "cadastro" })} role="button" bg="primary" className={`flex-grow-1 text-white card-dashboard ${this.state.classe === "cadastro" ? `card-dashboard-active` : ``}`}>
                            <Card.Header className="text-center">Designados ao Interessado</Card.Header>
                        </Card>
                    </Col>
                    <Col lg={2} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: "encarregado" })} role="button" bg="secondary" className={`flex-grow-1 text-white card-dashboard ${this.state.classe === "encarregado" ? `card-dashboard-active` : ``}`}>
                            <Card.Header className="text-center">Designados ao Encarregado</Card.Header>
                        </Card>
                    </Col>
                </Row>}
            </Container>
            <Datatable
                onChangeFilters={filters => this.setState({filters: filters.filter(f => f.value)})}
                filters={[
                    { name: "id", label: "Protocolo", value: this.props.ProtocoloId ?? "", span: 3 },
                    { name: "cnpj_cpf", label: "CNPJ/CPF", op: "clean", span: 3 },
                    { name: "nome_estabelecimento", label: "Nome/Estabelecimento", span: 6 },
                    { name: "$Cidade.nome$", label: "Cidade", span: 3 },
                    { name: "$Unidade.sigla$", label: "Unidade", span: 3 },
                    { name: "tipo", label: "Tipo", span: 3 },
                    { name: "status", label: "Status", span: 3 },
                    { name: "area", label: "Área Mín.", type: "number", op: "gte", span: 2 },
                    { name: "area", label: "Área Máx.", type: "number", op: "lte", span: 2 },
                    { name: "lotacao", label: "Lotação Mín.", type: "number", op: "gte", span: 2 },
                    { name: "lotacao", label: "Lotação Máx.", type: "number", op: "lte", span: 2 },
                    { name: "datahora_entrada", label: "Data Inicial", span: 2, type: "date", op: "gte" },
                    { name: "datahora_entrada", label: "Data Final", span: 2, type: "date", op: "lte" },
                ]}
                endpoint={`/protocolos/${this.state.classe}`}
                onAction={(action, callback) => this.onAction(action, callback)} />
        </>
    }
}

export default ProtocoloList;