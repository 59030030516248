import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Accordion, Badge, Button, Card, Col, Collapse, Container, Form, ListGroup, Row, Table } from "react-bootstrap";
import ReactSelect from 'react-select';
import InputMask from 'react-input-mask';
import moment from "moment";
import AppMap from "../componentes/AppMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import ActionForm from "./ActionForm";
import BoletoIsencaoForm from "../boletos/BoletoIsencaoForm";
import BoletoComplementoForm from "../boletos/BoletoComplementoForm";
import BoletoConfirmacaoForm from "../boletos/BoletoConfirmacaoForm";
import CreatableSelect from "react-select/creatable";
import BoletoDescontoForm from "../boletos/BoletoDescontoForm";
import ValoresModal from "./ValoresModal";

class ProtocoloForm extends Component {
    static contextType = AppContext;

    uploadAnexoRef = React.createRef();
    uploadComprovanteIdentidadeRef = React.createRef();
    uploadComprovanteResidenciaRef = React.createRef();

    state = {

        // Variáveis para todos os tipos de protocolo
        id: this.props.protocolo?.id ?? null,
        tipo: this.props.protocolo?.tipo ?? null,
        canal: this.props.protocolo?.canal ?? "CBM",
        status: this.props.protocolo?.status ?? "Protocolado",
        datahora_entrada: this.props.protocolo?.datahora_entrada ? moment(this.props.protocolo?.datahora_entrada) : moment(),
        nome_estabelecimento: this.props.protocolo?.nome_estabelecimento ?? this.props.protocolo?.Cadastro?.nome_razaosocial ?? "",
        evento_inicio: this.props.protocolo?.evento_inicio ? moment(this.props.protocolo?.evento_inicio) : null,
        evento_termino: this.props.protocolo?.evento_termino ? moment(this.props.protocolo?.evento_termino) : null,
        latitude: this.props.protocolo?.latitude ?? "",
        longitude: this.props.protocolo?.longitude ?? "",
        pavimentos: this.props.protocolo?.pavimentos ?? 0,
        area: this.props.protocolo?.area ?? 0,
        lotacao: this.props.protocolo?.lotacao ?? 0,
        endereco: this.props.protocolo?.endereco ?? this.props.protocolo?.Cadastro?.endereco ?? "",
        observacao_protocolo: this.props.protocolo?.observacao_protocolo ?? "",
        protocolo_regin: this.props.protocolo?.protocolo_regin ?? "",

        CidadeId: this.props.protocolo?.CidadeId ?? this.props.protocolo?.Cadastro?.CidadeId ?? null,
        BairroId: this.props.protocolo?.BairroId ?? (this.props.protocolo?.id ? null : this.props.protocolo?.Cadastro?.BairroId),
        AtividadeId: this.props.protocolo?.AtividadeId ?? this.props.protocolo?.Cadastro?.AtividadeId ?? null,
        RevendaglpId: this.props.protocolo?.RevendaglpId ?? null,
        UnidadeId: this.props.protocolo?.UnidadeId ?? (this.context.usuario?.classe === "usuario" ? this.context.usuario?.UnidadeId : null),
        CadastroId: this.props.protocolo?.CadastroId ?? this.props.protocolo?.Cadastro?.id ?? null,
        bairro: this.props.protocolo?.bairro ?? (this.props.protocolo?.id ? null : this.props.protocolo?.Cadastro?.bairro),

        Cadastro: this.props.protocolo?.Cadastro ?? null,
        Perguntas: this.props.protocolo?.Perguntas ?? [],
        Boletos: this.props.protocolo?.Boletos ?? [],
        Atribuicoes: this.props.protocolo?.Atribuicoes ?? [],
        Anexos: [],

        AnexoComprovanteResidencia: null,
        AnexoComprovanteIdentidade: null,

        BoletoEmissaoId: this.props.protocolo?.BoletoEmissaoId ?? null,
        BoletoAnaliseId: this.props.protocolo?.BoletoAnaliseId ?? null,
        BoletoVistoriaId: this.props.protocolo?.BoletoVistoriaId ?? null,

        // Variáveis do Licenciamento
        ProtocoloRenovacaoId: this.props.protocolo?.ProtocoloRenovacaoId || null,
        VinculoResponsavelProjetoId: this.props.protocolo?.VinculoResponsavelProjetoId || null,
        VinculoResponsavelExecucaoId: this.props.protocolo?.VinculoResponsavelExecucaoId || null,
        AnexoAnaliseId: this.props.protocolo?.AnexoAnaliseId || false, // Também para credenciamento
        analise_necessaria: this.props.protocolo?.analise_necessaria ?? false,
        vistoria_necessaria: this.props.protocolo?.vistoria_necessaria ?? false,
        dispensa_necessaria: this.props.protocolo?.dispensa_necessaria ?? false,
        analises_realizadas: this.props.protocolo?.analises_realizadas ?? 0,
        vistorias_realizadas: this.props.protocolo?.vistorias_realizadas ?? 0,

        // Variáveis da Renovação
        ProtocoloOriginalId: this.props.protocolo?.ProtocoloOriginalId || null,

        // Variáveis do Credenciamento
        ModalidadeId: this.props.protocolo?.ModalidadeId ?? null,
        VinculoResponsavelCredenciamentoId: this.props.protocolo?.VinculoResponsavelCredenciamentoId || null,
        VinculosInstrutores: this.props.protocolo?.VinculosInstrutores ?? [],

        // Variáveis da Anotação
        CursoId: this.props.protocolo?.CursoId || null,
        VinculoResponsavelAnotacaoId: this.props.protocolo?.VinculoResponsavelAnotacaoId || null,
        Alunos: this.props.protocolo?.Alunos ?? [],
        data_inicio: this.props.protocolo?.data_inicio? moment(this.props.protocolo?.data_inicio).format("YYYY-MM-DD") : null,
        data_fim: this.props.protocolo?.data_fim? moment(this.props.protocolo?.data_fim).format("YYYY-MM-DD") : null,
        carga_horaria: this.props.protocolo?.carga_horaria ?? 0,
        nota_minima: this.props.protocolo?.nota_minima ?? 0,
        local_treinamento: this.props.protocolo?.local_treinamento ?? "",

        // Variáveis da Fiscalização
        ProtocoloAutuacaoId: this.props.protocolo?.ProtocoloAutuacaoId || null,
        valor_multa: this.props.protocolo?.valor_multa ?? 0, // Também para Autuação
        Infracoes: this.props.protocolo?.Infracoes ?? [], // Também para Autuação
        CertificadosCassados: this.props.protocolo?.CertificadosCassados ?? [],

        //Variáveis da Autuação
        ProtocoloFiscalizacaoId: this.props.protocolo?.ProtocoloFiscalizacaoId || null,
        AtribuicaoJulgamentoPreliminarId: this.props.protocolo?.AtribuicaoJulgamentoPreliminarId || null,
        AtribuicaoJulgamentoProrrogacaoId: this.props.protocolo?.AtribuicaoJulgamentoProrrogacaoId || null,
        AtribuicaoJulgamentoRecursoId: this.props.protocolo?.AtribuicaoJulgamentoRecursoId || null,
        AtribuicaoJulgamentoTaacbId: this.props.protocolo?.AtribuicaoJulgamentoTaacbId || null,
        AtribuicaoVistoriaPreliminarId: this.props.protocolo?.AtribuicaoVistoriaPreliminarId || null,
        AtribuicaoVistoriaDefinitivaId: this.props.protocolo?.AtribuicaoVistoriaDefinitivaId || null,
        AtribuicaoVistoriaSancionatoriaId: this.props.protocolo?.AtribuicaoVistoriaSancionatoriaId || null,
        AnexoRecursoPreliminarId: this.props.protocolo?.AnexoRecursoPreliminarId || null,
        AnexoPedidoProrrogacaoId: this.props.protocolo?.AnexoPedidoProrrogacaoId || null,
        AnexoRecursoMultaId: this.props.protocolo?.AnexoRecursoMultaId || null,
        AnexoPedidoTaacbId: this.props.protocolo?.AnexoPedidoTaacbId || null,
        data_advertencia: this.props.protocolo?.data_advertencia ? moment(this.props.protocolo.data_advertencia) : null,
        data_recursopreliminar: this.props.protocolo?.data_recursopreliminar ? moment(this.props.protocolo.data_recursopreliminar) : null,
        data_pedidoprorrogacao: this.props.protocolo?.data_pedidoprorrogacao ? moment(this.props.protocolo.data_pedidoprorrogacao) : null,
        data_saneamentopreliminar: this.props.protocolo?.data_saneamentopreliminar ? moment(this.props.protocolo.data_saneamentopreliminar) : null,
        data_multasimples: this.props.protocolo?.data_multasimples ? moment(this.props.protocolo.data_multasimples) : null,
        data_recursomulta: this.props.protocolo?.data_recursomulta ? moment(this.props.protocolo.data_recursomulta) : null,
        data_saneamentodefinitivo: this.props.protocolo?.data_saneamentodefinitivo ? moment(this.props.protocolo.data_saneamentodefinitivo) : null,
        data_pedidotaacb: this.props.protocolo?.data_pedidotaacb ? moment(this.props.protocolo.data_pedidotaacb) : null,
        data_vistoriasancionatoria: this.props.protocolo?.data_vistoriasancionatoria ? moment(this.props.protocolo.data_vistoriasancionatoria) : null,
        data_multadobrada: this.props.protocolo?.data_multadobrada ? moment(this.props.protocolo.data_multadobrada) : null,
        data_dividaativa: this.props.protocolo?.data_dividaativa ? moment(this.props.protocolo.data_dividaativa) : null,
        BoletoMultaSimplesId: this.props.protocolo?.BoletoMultaSimplesId || null,
        BoletoMultaReduzidaId: this.props.protocolo?.BoletoMultaReduzidaId || null,
        BoletoMultaComplementarId: this.props.protocolo?.BoletoMultaComplementarId || null,
        BoletoMultaTaacbId: this.props.protocolo?.BoletoMultaTaacbId || null,
        BoletoMultaDobradaId: this.props.protocolo?.BoletoMultaDobradaId || null,

        // Listas auxiliares
        perguntas: [],
        cidades: [],
        bairros: [],
        atividades: [],
        infracoes: [],
        ocupacoes: [],
        unidades: [],
        modalidades: [],
        revendaglps: [],
        cursos: [],
        actions: [],
        tipos: [
            { value: "Licenciamento", label: "Licenciamento" },
            { value: "Credenciamento", label: "Credenciamento" },
            { value: "Renovação de Licenciamento", label: "Renovação de Licenciamento" },
            { value: "Renovação de Credenciamento", label: "Renovação de Credenciamento" },
            { value: "Anotação", label: "Anotação" },
            { value: "Fiscalização", label: "Fiscalização" },
            { value: "Autuação", label: "Autuação" },
            { value: "Outros Serviços", label: "Outros Serviços" },
        ],
        vinculos: [],
        arrStatus: [],

        valores: [],

        // Variáveis de estado do formulário
        VinculoInstrutorId: null,
        urls: [],
        modificadoLatLng: this.props.protocolo?.id || this.props.protocolo?.ProtocoloOriginalId ? true : false,
        provisorio: this.props.protocolo?.id || this.props.protocolo?.ProtocoloOriginalId ? this.props.protocolo?.evento_inicio !== null : null
    }

    updateForm() {
        this.context.request("GET", `/protocolos/${this.state.id}`).then(protocolo => {
            this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />);
        });
    }

    // Operações em Anexos
    downloadAnexo(anexo) {
        new Promise(resolve => {
            if (anexo.id) {
                this.context.request("GET", `/anexos/${anexo.id}/download`).then(resolve)
            } else {
                const leitor = new FileReader();
                leitor.onload = event => resolve(new Blob([event.target.result], { type: anexo.arquivo.type }));
                leitor.onerror = this.context.addToast({ header: "Erro", body: "Anexo não encontrado neste protocolo." });
                leitor.readAsArrayBuffer(anexo.arquivo);
            }
        }).then(blob => this.download(blob, anexo.nome));
    }
    deleteAnexo(anexo) {
        if (window.confirm("Deseja realmente excluir este anexo?")) {
            if (anexo.id) {
                this.context.request("DELETE", "/anexos/" + anexo.id)
                    .then(() => {
                        this.setState(state => ({ Anexos: state.Anexos.filter(a => a !== anexo) }))
                    });
            } else {
                this.setState(state => ({ Anexos: state.Anexos.filter(a => a !== anexo) }));
            }
        }
    }
    downloadAnexoAnalise() {
        const anexo = this.state.Anexos.find(a => a.id === this.state.AnexoAnaliseId);
        if (anexo) this.downloadAnexo(anexo);
        else {
            this.context.addToast({ header: "Erro", body: "Anexo não encontrado neste protocolo." });
        }
    }
    downloadAnexoRecursoPreliminar() {
        const anexo = this.state.Anexos.find(a => a.id === this.state.AnexoRecursoPreliminarId);
        if (anexo) this.downloadAnexo(anexo);
        else {
            this.context.addToast({ header: "Erro", body: "Anexo não encontrado neste protocolo." });
        }
    }
    downloadAnexoPedidoProrrogacao() {
        const anexo = this.state.Anexos.find(a => a.id === this.state.AnexoPedidoProrrogacaoId);
        if (anexo) this.downloadAnexo(anexo);
        else {
            this.context.addToast({ header: "Erro", body: "Anexo não encontrado neste protocolo." });
        }
    }
    downloadAnexoRecursoMulta() {
        const anexo = this.state.Anexos.find(a => a.id === this.state.AnexoRecursoMultaId);
        if (anexo) this.downloadAnexo(anexo);
        else {
            this.context.addToast({ header: "Erro", body: "Anexo não encontrado neste protocolo." });
        }
    }
    downloadAnexoPedidoTaacb() {
        const anexo = this.state.Anexos.find(a => a.id === this.state.AnexoPedidoTaacbId);
        if (anexo) this.downloadAnexo(anexo);
        else {
            this.context.addToast({ header: "Erro", body: "Anexo não encontrado neste protocolo." });
        }
    }

    // Operações em boletos
    downloadBoleto(boleto) {
        this.context.request("GET", `/boletos/${boleto.id}/download`)
            .then(blob => this.download(blob, `Boleto-${boleto.id}.pdf`));
    }
    deleteBoleto(boleto) {
        if (window.confirm("Deseja realmente excluir este boleto?")) {
            this.context.request("DELETE", `/boletos/${boleto.id}`)
                .then(() => this.updateForm());
        }
    }
    isentarBoleto(boleto) {
        this.context.openModal({
            title: "Isenção de Boleto",
            body:
                <BoletoIsencaoForm
                    onConfirm={data => {
                        this.context.request("POST", `/boletos/${boleto.id}/isentar`, data).then(() => {
                            this.context.addToast({ header: "Sucesso", body: "Boleto isentado com sucesso." });
                            this.context.closeModal();
                            this.updateForm();

                        });
                    }}
                    onCancel={() => this.context.closeModal()}
                />
        });
    }
    confirmarBoleto(boleto) {
        this.context.openModal({
            title: "Confirmação Manual de Boleto",
            body:
                <BoletoConfirmacaoForm
                    onConfirm={data => {
                        this.context.request("POST", `/boletos/${boleto.id}/confirmar`, data).then(() => {
                            this.context.addToast({ header: "Sucesso", body: "Boleto confirmado com sucesso." });
                            this.context.closeModal();
                            this.updateForm();
                        });
                    }}
                    onCancel={() => this.context.closeModal()}
                />
        });
    }
    descontarBoleto(boleto) {
        this.context.openModal({
            title: "Desconto de Boleto",
            params: { size: "lg" },
            body:
                <BoletoDescontoForm
                    onConfirm={data => {
                        this.context.request("POST", `/boletos/${boleto.id}/descontar`, data).then(() => {
                            this.context.addToast({ header: "Sucesso", body: "Boleto descontado com sucesso." });
                            this.context.closeModal();
                            this.updateForm();
                        });
                    }}
                    onCancel={() => this.context.closeModal()}
                />
        });
    }
    complementarBoleto(boleto) {
        this.context.openModal({
            title: "Complementar Boleto", body:
                <BoletoComplementoForm
                    onConfirm={data => {
                        this.context.request("POST", `/boletos/${boleto.id}/complementar`, data).then(() => {
                            this.context.addToast({ header: "Sucesso", body: "Boleto complementado com sucesso." });
                            this.context.closeModal();
                            this.updateForm();
                        });
                    }}
                    onCancel={() => this.context.closeModal()}
                />
        });
    }
    downloadBoletoMultaSimples() {
        const boleto = this.state.Boletos.find(a => a.id === this.state.BoletoMultaSimplesId);
        if (boleto) this.downloadBoleto(boleto);
        else {
            this.context.addToast({ header: "Erro", body: "Boleto não encontrado neste protocolo." });
        }
    }
    downloadBoletoMultaReduzida() {
        const boleto = this.state.Boletos.find(a => a.id === this.state.BoletoMultaReduzidaId);
        if (boleto) this.downloadBoleto(boleto);
        else {
            this.context.addToast({ header: "Erro", body: "Boleto não encontrado neste protocolo." });
        }
    }
    downloadBoletoMultaComplementar() {
        const boleto = this.state.Boletos.find(a => a.id === this.state.BoletoMultaComdownloadBoletoMultaComplementarId);
        if (boleto) this.downloadBoleto(boleto);
        else {
            this.context.addToast({ header: "Erro", body: "Boleto não encontrado neste protocolo." });
        }
    }
    downloadBoletoMultaTaacb() {
        const boleto = this.state.Boletos.find(a => a.id === this.state.BoletoMultaTaacbId);
        if (boleto) this.downloadBoleto(boleto);
        else {
            this.context.addToast({ header: "Erro", body: "Boleto não encontrado neste protocolo." });
        }
    }
    downloadBoletoMultaDobrada() {
        const boleto = this.state.Boletos.find(a => a.id === this.state.BoletoMultaDobradaId);
        if (boleto) this.downloadBoleto(boleto);
        else {
            this.context.addToast({ header: "Erro", body: "Boleto não encontrado neste protocolo." });
        }
    }

    onUpload(event) {
        const { files } = event.target;
        for (let i = 0; i < files.length; i++) {
            this.setState(state => ({
                Anexos: [...state.Anexos, {
                    nome: files[i].name,
                    descricao: "Enviado pelo formulário",
                    arquivo: files[i]
                }]
            }));
        }
    }

    onUploadComprovanteResidencia(event) {
        const { files } = event.target;
        if (files.length) {
            this.setState({
                AnexoComprovanteResidencia: {
                    nome: files[0].name,
                    descricao: "Comprovante de Residência",
                    arquivo: files[0]
                }
            });
        }
    }

    onUploadComprovanteIdentidade(event) {
        const { files } = event.target;
        if (files.length) {
            this.setState({
                AnexoComprovanteIdentidade: {
                    nome: files[0].name,
                    descricao: "Comprovante de Identidade",
                    arquivo: files[0]
                }
            });
        }
    }

    componentDidMount() {

        const { id, CadastroId, Cadastro } = this.state;

        this.context.load([
            new Promise((resolve, reject) => this.context.request("GET", "/perguntas/options").then(perguntas => this.setState({ perguntas }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request("GET", "/cidades/options").then(cidades => this.setState({ cidades }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request("GET", "/atividades/options").then(atividades => this.setState({ atividades }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request("GET", "/unidades/options").then(unidades => this.setState({ unidades }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request("GET", "/revendaglps/options").then(revendaglps => this.setState({ revendaglps }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request("GET", "/modalidades/options").then(modalidades => this.setState({ modalidades }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request("GET", "/cursos/options").then(cursos => this.setState({ cursos }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request("GET", "/infracoes/options").then(infracoes => this.setState({ infracoes }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request("GET", "/protocolos/options/status").then(arrStatus => this.setState({ arrStatus }, resolve)).catch(reject)),
            new Promise((resolve, reject) => this.context.request(`GET`, `/cadastros/${CadastroId}/vinculos/options?tipoVinculo=Vinculado`).then(vinculos => this.setState({ vinculos }, resolve)).catch(reject)),
            (id !== null) ? Promise.all([
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/alunos`).then(Alunos => this.setState({ Alunos }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/perguntas`).then(Perguntas => this.setState({ Perguntas: Perguntas.map(pergunta => pergunta.id) }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/anexos`).then(Anexos => this.setState({
                    Anexos: Anexos.filter(a => a.descricao !== "Comprovante de Residência" && a.descricao !== "Comprovante de Identidade"),
                    AnexoComprovanteResidencia: Anexos.find(a => a.descricao === "Comprovante de Residência") ?? null,
                    AnexoComprovanteIdentidade: Anexos.find(a => a.descricao === "Comprovante de Identidade") ?? null,
                }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/boletos`).then(Boletos => this.setState({ Boletos }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/infracoes`).then(Infracoes => this.setState({ Infracoes: Infracoes.map(infracao => infracao.id) }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/atribuicoes`).then(Atribuicoes => this.setState({ Atribuicoes }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/actions`).then(actions => this.setState({ actions: actions.filter(action => !action.hidden) }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/vinculos/instrutores`).then(VinculosInstrutores => this.setState({ VinculosInstrutores: VinculosInstrutores.map(vinculo => vinculo.id) }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/certificados/cassados`).then(CertificadosCassados => this.setState({ CertificadosCassados }, resolve)).catch(reject)),
                new Promise((resolve, reject) => this.context.request(`GET`, `/protocolos/${id}/valores`).then(valores => this.setState({ valores }, resolve)).catch(reject))
            ]) : null,
            (!Cadastro) ?
                new Promise((resolve, reject) => this.context.request(`GET`, `/cadastros/${CadastroId}`).then(Cadastro => this.setState({ Cadastro }, resolve)).catch(reject)) : null,
            (this.state.CidadeId) ?
                new Promise((resolve, reject) => this.context.request("GET", `/cidades/${this.state.CidadeId}/bairros/options`).then(bairros => this.setState(state => ({ bairros: [...bairros, { CidadeId: state.CidadeId, label: state.bairro, value: null }] }), resolve)).catch(reject)) : null
        ]).then(() => {
            this.setState(state => ({
                perguntas: state.perguntas.map(pergunta => ({
                    ...pergunta,
                    resposta: !id && !state.ProtocoloOriginalId ? null : state.Perguntas.indexOf(pergunta.value) > -1
                }))
            }))
        });
    }
    changeCidade(option) {
        this.setState({ BairroId: null, CidadeId: option.value ?? null }, () => {
            if (this.state.CidadeId) {
                this.context.request("GET", `/cidades/${this.state.CidadeId}/bairros/options`).then(bairros => this.setState({ bairros }));
            }
        });
    }
    submit(event) {

        event.preventDefault();

        if (!this.state.modificadoLatLng) {
            window.alert("O Georreferenciamento do endereço deve ser informado alterando a posição do marcador no mapa!");
            return;
        } else if (this.state.tipo === "Anotação" && !this.state.VinculosInstrutores.length) {
            window.alert("Deve ser indicado ao menos um instrutor na ARP.");
            return;
        } else if (this.state.tipo === "Anotação" && !this.state.Alunos.length) {
            window.alert("Deve ser indicado ao menos um aluno na ARP.");
            return;
        } else if (this.context.usuario.classe !== "usuario" && !this.state.id && this.state.tipo !== "Fiscalização" && !this.state.AnexoComprovanteIdentidade) {
            window.alert("Selecione um arquivo de comprovante de identidade.");
            return;
        } else if (this.context.usuario.classe !== "usuario" && !this.state.id && this.state.tipo !== "Fiscalização" && !this.state.AnexoComprovanteResidencia) {
            window.alert("Selecione um arquivo de comprovante de residência.");
            return;
        }

        const {
            id,
            tipo,
            canal,
            status,
            nome_estabelecimento,
            evento_inicio,
            evento_termino,
            latitude,
            longitude,
            pavimentos,
            protocolo_regin,
            area,
            lotacao,
            endereco,
            ProtocoloOriginalId,
            CidadeId,
            BairroId,
            bairro,
            AtividadeId,
            RevendaglpId,
            observacao_protocolo,
            UnidadeId,
            CadastroId,
            Anexos,
            AnexoComprovanteResidencia,
            AnexoComprovanteIdentidade,
            VinculoResponsavelProjetoId,
            VinculoResponsavelExecucaoId,
            ModalidadeId,
            VinculoResponsavelCredenciamentoId,
            VinculosInstrutores,
            CursoId,
            VinculoResponsavelAnotacaoId,
            Alunos,
            data_inicio,
            data_fim,
            carga_horaria,
            nota_minima,
            local_treinamento,
            Infracoes,
            perguntas
        } = this.state;

        const method = id ? "PUT" : "POST";

        const url = `/protocolos/${id ?? ''}`;

        this.context.request(method, url, {
            tipo,
            canal,
            status,
            nome_estabelecimento,
            evento_inicio,
            evento_termino,
            latitude,
            longitude,
            pavimentos,
            area,
            lotacao,
            endereco,
            ProtocoloOriginalId,
            protocolo_regin,
            CidadeId,
            BairroId,
            bairro,
            AtividadeId,
            RevendaglpId,
            observacao_protocolo,
            UnidadeId,
            CadastroId,
            Perguntas: perguntas.filter(pergunta => pergunta.resposta).map(pergunta => pergunta.value), // São enviadas somente as perguntas com resposta "Sim"
            Anexos: Anexos.filter(anexo => anexo.id), // Os anexos novos são enviados separadamente abaixo
            AnexoComprovanteResidencia, // Os anexos novos são enviados separadamente abaixo
            AnexoComprovanteIdentidade, // Os anexos novos são enviados separadamente abaixo
            VinculoResponsavelProjetoId,
            VinculoResponsavelExecucaoId,
            ModalidadeId: (tipo === "Credenciamento" || tipo === "Renovação de Credenciamento") ? ModalidadeId : null,
            VinculoResponsavelCredenciamentoId,
            VinculosInstrutores,
            CursoId,
            VinculoResponsavelAnotacaoId,
            Alunos,
            data_inicio,
            data_fim,
            carga_horaria,
            nota_minima,
            local_treinamento,
            Infracoes
        })
            .then(async protocolo => {

                const Anexos = [
                    ...this.state.Anexos,
                    this.state.AnexoComprovanteIdentidade,
                    this.state.AnexoComprovanteResidencia
                ].filter(anexo => anexo && !anexo.id);

                await Promise.all(Anexos.map(anexo => {
                    const data = new FormData();
                    data.append("ProtocoloId", protocolo.id);
                    data.append("nome", anexo.nome);
                    data.append("descricao", anexo.descricao);
                    data.append("arquivo", anexo.arquivo);
                    return this.context.request("POST", "/anexos", data);
                }));

                return protocolo;

            })
            .then(protocolo => {
                this.context.addToast({ header: "Sucesso", body: "Dados gravados com sucesso." });
                this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />);
            })
            .catch(error => {
                this.context.addToast({ header: "Erro", body: "Falha na gravação dos dados: " + error.toString() });
            })
    }
    arquivar() {
        this.context.request("PATCH", `/protocolos/${this.props.protocolo.id}/arquivar`)
            .then(() => {
                this.context.closeModal();
                this.context.addToast({ header: "Sucesso", body: "Ação realizada com sucesso!" });
                this.context.request("GET", `/protocolos/${this.props.protocolo.id}`).then(protocolo =>
                    this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
            });
    }
    desarquivar() {
        this.context.request("PATCH", `/protocolos/${this.props.protocolo.id}/desarquivar`)
            .then(() => {
                this.context.closeModal();
                this.context.addToast({ header: "Sucesso", body: "Ação realizada com sucesso!" });
                this.context.request("GET", `/protocolos/${this.props.protocolo.id}`).then(protocolo =>
                    this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
            });
    }
    download(blob, filename) {
        return new Promise(resolve => {
            const url = URL.createObjectURL(blob);
            this.setState(state => ({ urls: [...state.urls, url] }), () => {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                resolve();
            });
        });
    }
    componentWillUnmount() {
        this.state.urls.forEach(url => URL.revokeObjectURL(url));
    }
    downloadCertificado() {
        this.context.request("GET", `/certificados/${this.props.protocolo.CertificadoEmitidoId}/download`).then(blob => this.download(blob, "Certificado-CBMPA.pdf"));
    }
    renovarBoleto(boleto) {
        this.doAction("Renovar Boleto", { BoletoId: boleto.id });
    }
    doAction(actionName, payload) {
        this.context.request("POST", `/protocolos/${this.props.protocolo.id}/actions?actionName=${actionName}`, payload)
            .then(async ({ BoletoId, CertificadoId, ProtocoloAutuacaoId }) => {

                this.context.closeModal();
                this.context.addToast({ header: "Sucesso", body: "Ação realizada com sucesso!" });

                if (BoletoId) {
                    const boletoBlob = await this.context.request("GET", `/boletos/${BoletoId}/download`);
                    await this.download(boletoBlob, `Boleto-CBMPA-${BoletoId}.pdf`);
                }

                if (CertificadoId) {
                    const certificadoBlob = await this.context.request("GET", `/certificados/${CertificadoId}/download`);
                    await this.download(certificadoBlob, `Certificado-CBMPA-${CertificadoId}.pdf`);
                }

                if (ProtocoloAutuacaoId) {
                    this.context.request("GET", `/protocolos/${ProtocoloAutuacaoId}`).then(protocolo => this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
                } else {
                    this.context.request("GET", `/protocolos/${this.props.protocolo.id}`).then(protocolo => this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
                }

            });
    }
    onAction(action) {

        const doAction = (payload, callback) => {
            this.context.request("POST", `/protocolos/${this.props.protocolo.id}/actions?actionName=${action.name}`, payload)
                .then(async ({ BoletoId, CertificadoId, ProtocoloAutuacaoId }) => {

                    this.context.closeModal();
                    this.context.addToast({ header: "Sucesso", body: "Ação realizada com sucesso!" });

                    if (BoletoId) {
                        const boletoBlob = await this.context.request("GET", `/boletos/${BoletoId}/download`);
                        await this.download(boletoBlob, `Boleto-CBMPA-${BoletoId}.pdf`);
                    }

                    if (CertificadoId) {
                        const certificadoBlob = await this.context.request("GET", `/certificados/${CertificadoId}/download`);
                        await this.download(certificadoBlob, `Certificado-CBMPA-${CertificadoId}.pdf`);
                    }

                    if (ProtocoloAutuacaoId) {
                        this.context.request("GET", `/protocolos/${ProtocoloAutuacaoId}`).then(protocolo => this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
                    } else {
                        this.context.request("GET", `/protocolos/${this.props.protocolo.id}`).then(protocolo => this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
                    }

                })
                .finally(callback);
        }

        this.context.openModal({ title: action.name, params: { size: "lg" }, body: <ActionForm protocolo={this.props.protocolo} action={action} onCancel={() => this.context.closeModal()} onConfirm={doAction} /> });

    }
    gotoAutuacao() {
        this.context.request("GET", `/protocolos/${this.state.ProtocoloAutuacaoId}`).then(protocolo => this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
    }
    gotoFiscalizacao() {
        this.context.request("GET", `/protocolos/${this.state.ProtocoloFiscalizacaoId}`).then(protocolo => this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
    }
    gotoFiscalizado() {
        this.context.request("GET", `/protocolos/${this.state.ProtocoloOriginalId}`).then(protocolo => this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.props.onCancel()} />));
    }    
    searchCoords() {

        const Cidade = this.state.cidades.find(option => option.value === this.state.CidadeId);
        const Bairro = this.state.bairros.find(option => option.value === this.state.BairroId);

        const query = `${this.state.endereco}, ${Bairro.label}, ${Cidade.label}, Brasil`;

        fetch(`https://nominatim.openstreetmap.org/search?q=${query}&format=json&limit=1`)
            .then(response => response.json())
            .then(results => {
                if (results.length) {
                    const { lat, lon } = results[0];
                    this.setState({ latitude: lat, longitude: lon });
                } else {
                    const queryReduzida = `${Bairro.label}, ${Cidade.label}, Brasil`;

                    fetch(`https://nominatim.openstreetmap.org/search?q=${queryReduzida}&format=json&limit=1`)
                        .then(response => response.json())
                        .then(results => {
                            if (results.length) {
                                const { lat, lon } = results[0];
                                this.setState({ latitude: lat, longitude: lon });
                            } else {
                                this.context.addToast({ header: "Não encontrado", body: "Nenhum resultado encontrado. Por favor, clique no mapa para indicar o ponto do estabelecimento ou tente especificar o endereço sem abreviaturas e com numerais por extenso (Ex. Travessa WE Sessenta e Um, em vez de Tv. WE-61)." });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.context.addToast({ header: "Erro na pesquisa", body: error.toString() });
                        });
                }
            })
            .catch(error => {
                console.log(error);
                this.context.addToast({ header: "Erro na pesquisa", body: error.toString() });
            });
    }

    render() {

        const actions = this.state.actions.filter(action =>
            action.tipo === "cadastro" ||
            (action.tipo === "usuario" && this.context.usuario.Recursos.find(r => r === action.name)) ||
            (action.tipo === "admin" && this.context.usuario.Recursos.find(r => r === "Administrar Protocolo"))
        );

        return <Container>
            <Form className="app-form" onSubmit={e => this.submit(e)}>
                <h2 className="d-flex justify-content-between">
                    <div><FontAwesomeIcon icon={Icons.faFile} /> Formulário de Protocolo</div>
                    <div>
                        <Button variant="secondary" className="ms-auto me-1" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={Icons.faFastBackward} /> Voltar
                        </Button>

                        {this.props.protocolo.id && this.props.protocolo.status !== "Protocolo Arquivado" && this.context.usuario.Recursos.find(r => r === "Arquivar Protocolo") &&
                            <Button className="me-1" variant="danger" onClick={() => this.arquivar()} title="Arquivar Protocolo">
                                <FontAwesomeIcon icon={Icons.faArchive} /> Arquivar Protocolo
                            </Button>}

                        {this.props.protocolo.id && this.props.protocolo.status === "Protocolo Arquivado" && this.context.usuario.Recursos.find(r => r === "Desarquivar Protocolo") &&
                            <Button className="me-1" variant="info" onClick={() => this.desarquivar()} title="Desarquivar Protocolo">
                                <FontAwesomeIcon icon={Icons.faArchive} /> Desarquivar Protocolo
                            </Button>}

                        {this.props.protocolo.id && this.props.protocolo.CertificadoEmitidoId &&
                            <Button className="me-1" variant="success" onClick={() => this.downloadCertificado()} title="Baixar Certificado">
                                <FontAwesomeIcon icon={Icons.faDownload} /> Baixar Certificado
                            </Button>}

                        {((
                            !this.state.id && (this.context.usuario.classe === "cadastro" || this.context.usuario.Recursos.find(r => r === "Adicionar Protocolo"))
                        ) || this.context.usuario.Recursos.find(r => r === "Atualizar Protocolo")) &&
                            <Button type="submit" variant="primary">
                                <FontAwesomeIcon icon={Icons.faSave} /> Gravar
                            </Button>}
                    </div>
                </h2>
                <Row>
                    <h4 className="mb-3">
                        Ações Disponíveis
                    </h4>
                    <div className="d-flex flex-wrap">
                    {actions.length ? actions.map((action, key) =>
                        <Button className="m-1 flex-fill" key={key} variant={action.variant} onClick={() => this.onAction(action)} title={action.name}>
                            <FontAwesomeIcon icon={Icons[action.icon ?? 'faEdit']} /> {action.name}
                        </Button>
                    ) : "Nenhuma ação disponível."}
                    </div>
                </Row>
                <hr />
                <h4 className="mb-3 d-flex justify-content-between align-items-center">
                    <span>Dados Gerais</span>
                    <Button variant="success" onClick={() => this.context.openModal({title: "Valores de Referência", body: <ValoresModal valores={this.state.valores} onCancel={() => this.context.closeModal()}/>})}>
                        <FontAwesomeIcon icon={Icons.faDollarSign} /> Valores de Referência
                    </Button>
                </h4>
                <Row>
                    <Col lg={3} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Nº do Protocolo</b></Form.Label>
                            <Form.Control disabled value={this.state.id ? this.state.id.toString().padStart(6, '0') : "(Em edição)"} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Tipo de Protocolo</b></Form.Label>
                            <ReactSelect
                                options={this.state.tipos}
                                value={this.state.tipos.find(option => option.value === this.state.tipo)}
                                onChange={option => this.setState({ tipo: option.value ?? null })}
                                placeholder="Selecione um tipo..."
                                noOptionsMessage={() => "Sem opções"}
                                required
                                isDisabled={!this.context.usuario.Recursos.find(r => r === "Administrar Protocolo")}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Status do Protocolo</b></Form.Label>
                            <ReactSelect
                                options={this.state.arrStatus.filter(option => !option.tipo || option.tipo === this.state.tipo)}
                                value={this.state.arrStatus.find(option => option.value === this.state.status)}
                                onChange={option => this.setState({ status: option.value ?? null })}
                                placeholder="(Status Inicial)"
                                noOptionsMessage={() => "Sem opções"}
                                isDisabled={!this.state.id || !this.context.usuario.Recursos.find(r => r === "Administrar Protocolo")}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={8} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Nome/Razão Social</b></Form.Label>
                            <Form.Control disabled value={this.state.Cadastro?.nome_razaosocial ?? ""} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>CNPJ/CPF</b></Form.Label>
                            <Form.Control disabled value={this.state.Cadastro?.cnpj_cpf ?? ""} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Data/Hora do Protocolo</b></Form.Label>
                            <Form.Control disabled value={this.state.datahora_entrada.format("DD/MM/YYYY HH:mm")} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Unidade de Atendimento</b></Form.Label>
                            <ReactSelect
                                options={this.state.unidades}
                                value={this.state.unidades.find(option => option.value === this.state.UnidadeId)}
                                onChange={option => this.setState({ UnidadeId: option?.value ?? null })}
                                placeholder="(Definir Automaticamente)"
                                noOptionsMessage={() => "Sem opções"}
                                isClearable={true}
                                isDisabled={!this.context.usuario.Recursos.find(r => r === "Administrar Protocolo") && !this.context.usuario.Recursos.find(r => r === "Transferir Protocolo")}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Canal de Atendimento</b></Form.Label>
                            <Form.Select
                                disabled={!this.context.usuario.Recursos.find(r => r === "Administrar Protocolo")}
                                required value={this.state.canal} onChange={e => this.setState({ canal: e.target.value })}>
                                <option></option>
                                <option>CBM</option>
                                <option>REGIN</option>
                                <option>WEB</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Protocolo REGIN</b></Form.Label>
                            <Form.Control disabled={!this.context.usuario.Recursos.find(r => r === "Administrar Protocolo")} value={this.state.protocolo_regin} onChange={e => this.setState({ protocolo_regin: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-3">
                        <Form.Group className="card-body bg-danger text-white p-3" style={{ border: "solid 1px gray", borderRadius: "5px" }}>
                            <Form.Label className="required"><b>Nome / Estabelecimento</b></Form.Label>
                            <Form.Control required value={this.state.nome_estabelecimento} onChange={e => this.setState({ nome_estabelecimento: e.target.value })} />
                            <i>
                                Observe que esta informação constará no Certificado emitido, quando for o caso, e será utilizada para identificar diferentes estabelecimentos que estejam cadastrados sob o mesmo CPF/CNPJ. Insira a Razão Social, Nome Fantasia ou Nome Completo no caso de haver apenas um Estabelecimento ou tratar-se de Pessoa Física.
                            </i>
                        </Form.Group>
                    </Col>
                </Row>
                {this.state.tipo !== "Fiscalização" &&
                    <Row>
                        <Col lg={6} className="mb-3">
                            <Form.Label className={this.state.AnexoComprovanteIdentidade ? "" : "force-required"}><b>Comprovante de Identidade</b></Form.Label>
                            <Button size="sm" variant={this.state.AnexoComprovanteIdentidade ? "success" : "warning"} className="form-control" onClick={() => this.state.AnexoComprovanteIdentidade?.id ? this.downloadAnexo(this.state.AnexoComprovanteIdentidade) : this.uploadComprovanteIdentidadeRef.current.click()}>
                                {this.state.AnexoComprovanteIdentidade ? this.state.AnexoComprovanteIdentidade.nome : "Selecionar Arquivo"}
                            </Button>
                            <input type="file" className="d-none" ref={this.uploadComprovanteIdentidadeRef} onChange={e => this.onUploadComprovanteIdentidade(e)} />
                            <i className="text-danger">Selecione um arquivo que contenha a imagem ou PDF {this.state.Cadastro?.tipo === "Pessoa Física" ? "com frente e verso do documento de identificação da pessoa interessada" : "do cartão CNPJ do estabelecimento"}.</i>

                        </Col>
                        <Col lg={6} className="mb-3">
                            <Form.Label className={this.state.AnexoComprovanteIdentidade ? "" : "force-required"}><b>Comprovante de Endereço</b></Form.Label>
                            <Button size="sm" variant={this.state.AnexoComprovanteResidencia ? "success" : "warning"} className="form-control" onClick={() => this.state.AnexoComprovanteResidencia?.id ? this.downloadAnexo(this.state.AnexoComprovanteResidencia) : this.uploadComprovanteResidenciaRef.current.click()}>
                                {this.state.AnexoComprovanteResidencia ? this.state.AnexoComprovanteResidencia.nome : "Selecionar Arquivo"}
                            </Button>
                            <input type="file" className="d-none" ref={this.uploadComprovanteResidenciaRef} onChange={e => this.onUploadComprovanteResidencia(e)} />
                            <i className="text-danger">Selecione um comprovante no nome do interessado que contenha o endereço do local onde será prestado o serviço. </i>
                        </Col>
                    </Row>}
                <Row>
                    <Col lg={12} className="mb-3">
                        <Form.Group>
                            <Form.Label className="required"><b>Endereço Completo</b></Form.Label>
                            <Form.Control placeholder="Logradouro, Número, Complemento, Bairro, Cidade-UF, CEP e Ponto de Referência" rows={4} as="textarea" required value={this.state.endereco} onChange={e => this.setState({ endereco: e.target.value })} />
                            <i className="text-danger">Insira o endereçamento completo (Logradouro, Número, Complemento, Bairro, Cidade-UF, CEP e Ponto de Referência) e então clique em <b>Pesquisar Coordenadas</b> para obter um local aproximado no mapa.</i>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-3">
                        <Form.Group>
                            <Form.Label className={this.state.CidadeId ? "required" : "force-required"}><b>Cidade</b></Form.Label>
                            <ReactSelect
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                options={this.state.cidades}
                                value={this.state.cidades.find(option => option.value === this.state.CidadeId)}
                                onChange={option => this.changeCidade(option)}
                                placeholder="Selecione uma cidade..."
                                noOptionsMessage={() => "Sem opções"}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-3">
                        <Form.Group>
                            <Form.Label className={this.state.bairro ? "required" : "force-required"}><b>Bairro</b></Form.Label>
                            <CreatableSelect
                                styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                menuPortalTarget={document.body}
                                options={this.state.bairros}
                                value={this.state.bairros.find(option => option.value === this.state.BairroId)}
                                formatCreateLabel={label => "Criar Bairro: " + label}
                                onCreateOption={label => this.setState(state => ({ BairroId: null, bairro: label, bairros: [{ value: null, label, CidadeId: this.state.CidadeId }, ...state.bairros] }))}
                                onChange={option => this.setState({ BairroId: option?.value ?? null, bairro: option?.label })}
                                placeholder="Selecione ou adicione um bairro..."
                                noOptionsMessage={() => "Sem opções"}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="d-flex align-items-end">
                        <Button className="form-control mb-3" variant="primary" onClick={() => this.searchCoords()}>
                            <FontAwesomeIcon icon={Icons.faSearch} /> Pesquisar Coordenadas
                        </Button>
                    </Col>

                    <Col lg={12} className="mb-3">

                        <Form.Group>
                            <Form.Label className="required"><b>Georreferenciamento</b></Form.Label>
                            <div>
                                <AppMap latitude={this.state.latitude} longitude={this.state.longitude} onChange={(latitude, longitude, modificadoLatLng) => this.setState(state => ({ latitude, longitude, modificadoLatLng: state.modificadoLatLng || modificadoLatLng }))} />
                            </div>
                        </Form.Group>
                        <i className="text-danger">Clique em um ponto no mapa para ajustar o local do estabelecimento. Utilize o controle de zoom caso necessário.</i>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Latitude</b></Form.Label>
                            <Form.Control required type="number" step="0.000001" value={this.state.latitude} onChange={e => this.setState({ latitude: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Longitude</b></Form.Label>
                            <Form.Control required type="number" step="0.000001" value={this.state.longitude} onChange={e => this.setState({ longitude: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                {this.state.Cadastro?.tipo === "Pessoa Jurídica" &&
                    <Row>
                        <Col lg={4} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required"><b>Nº de Pavimentos</b></Form.Label>
                                <Form.Control type="number" min="1" step="1" required value={this.state.pavimentos} onChange={e => this.setState({ pavimentos: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required"><b>Área (m²)</b></Form.Label>
                                <Form.Control type="number" min="0.01" step="0.01" required value={this.state.area} onChange={e => this.setState({ area: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required"><b>Lotação (fixa e flutuante)</b></Form.Label>
                                <Form.Control type="number" min="1" step="1" required value={this.state.lotacao} onChange={e => this.setState({ lotacao: e.target.value })} />
                            </Form.Group>
                        </Col>
                    </Row>}
                <Row>
                    <Col lg={12} className="mb-3">
                        <Form.Group className="card-body bg-light p-3" style={{ border: "solid 1px gray", borderRadius: "5px" }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="me-3">
                                    <Form.Label><b>Trata-se de instalação provisória para shows e eventos, tais como arquibancadas, palanques, plataformas, circo, parques de diversão e outros gêneros?</b></Form.Label>
                                </div>
                                <div>
                                    <Form.Check type="radio" name={`provisorio`} required checked={this.state.provisorio === true} onChange={e => this.setState({ provisorio: e.target.checked })} label="Sim" />
                                    <Form.Check type="radio" name={`provisorio`} required checked={this.state.provisorio === false} onChange={e => this.setState({ provisorio: !e.target.checked })} label="Não" />
                                </div>
                            </div>
                            {this.state.provisorio && <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label className="required"><b>Início do Evento</b></Form.Label>
                                        <Form.Control type="date" required value={this.state.evento_inicio?.format("YYYY-MM-DD")} onChange={e => this.setState({ evento_inicio: moment(e.target.value) })} />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label className="required"><b>Término do Evento</b></Form.Label>
                                        <Form.Control type="date" required value={this.state.evento_termino?.format("YYYY-MM-DD")} onChange={e => this.setState({ evento_termino: moment(e.target.value) })} />
                                    </Form.Group>
                                </Col>
                                <i className="text-danger">Neste caso, o boleto da taxa terá vencimento igual a data de início do evento e o certificado gerado possuirá validade até o término do evento.</i>
                            </Row>}
                        </Form.Group>
                    </Col>
                </Row>
                {["Licenciamento", "Renovação de Licenciamento", "Fiscalização", "Autuação"].indexOf(this.state.tipo) > -1 &&
                    !this.state.provisorio && <>
                        <hr />
                        <h4 className="mb-3">
                            Dados de Risco
                        </h4>
                        {this.state.Cadastro?.tipo === "Pessoa Jurídica" &&
                            <Row>
                                <Col lg={8} className="mb-3">
                                    <Form.Group>
                                        <Form.Label className={this.state.AtividadeId ? "required" : "force-required"}><b>Atividade Econômica</b></Form.Label>
                                        <ReactSelect
                                            options={this.state.atividades}
                                            value={this.state.atividades.find(option => option.value === this.state.AtividadeId)}
                                            onChange={option => this.setState({ AtividadeId: option?.value ?? null })}
                                            placeholder="Selecione uma atividade..."
                                            noOptionsMessage={() => "Sem opções"}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4} className="mb-3">
                                    <Form.Group>
                                        <Form.Label><b>Revenda de GLP</b></Form.Label>
                                        <ReactSelect
                                            options={this.state.revendaglps}
                                            value={this.state.revendaglps.find(option => option.value === this.state.RevendaglpId)}
                                            onChange={option => this.setState({ RevendaglpId: option?.value ?? null })}
                                            placeholder="Selecione uma Revenda..."
                                            noOptionsMessage={() => "Sem opções"}
                                            isClearable={true}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>}
                        <Row>
                            <Col lg={12} className="mb-3">
                                <i className="text-danger">Preencha o questionário abaixo de acordo com as características do estabelecimento</i>
                                <Card className={"mb-3 p-2 " + (this.state.perguntas.filter(pergunta => pergunta.resposta === null).length ? "bg-warning" : "bg-info")}>
                                    <Card.Header title="Clique para visualizar o questionário de risco" role="button" className={this.state.perguntas.filter(pergunta => pergunta.resposta === null).length ? "bg-danger" : "bg-success"} onClick={() => this.setState(state => ({ toggle_questionario: !state.toggle_questionario }))}>
                                        <div className="w-100 mr-3 d-flex justify-content-between align-items-center text-white">
                                            <b>Questionário de Risco</b> <Badge className="bg-warning">{this.state.perguntas.filter(p => p.resposta).length ? this.state.perguntas.filter(p => p.resposta).length : ''}</Badge>
                                        </div>
                                    </Card.Header>
                                    <Collapse in={this.state.toggle_questionario}>
                                        <ListGroup variant="flush">
                                            {this.state.perguntas.map((pergunta, key) =>
                                                <ListGroup.Item role="button" key={key} className={`d-flex justify-content-between align-items-start ${pergunta.resposta ? 'bg-warning' : ''}`}>
                                                    <div>
                                                        <Badge className="me-3" bg={{ "Análise": "danger", "Vistoria": "warning", "ACPS": "info", "Dispensa": "success" }[pergunta.criterio] ?? "secondary"}>{pergunta.criterio}</Badge>
                                                        {pergunta.label}
                                                    </div>
                                                    <div>
                                                        <Form.Check type="radio" name={`pergunta-${key}`} required checked={pergunta.resposta === true} onChange={e => this.setState(state => ({ perguntas: state.perguntas.map(p => p === pergunta ? { ...p, resposta: e.target.checked } : p) }))} label="Sim" />
                                                        <Form.Check type="radio" name={`pergunta-${key}`} required checked={pergunta.resposta === false} onChange={e => this.setState(state => ({ perguntas: state.perguntas.map(p => p === pergunta ? { ...p, resposta: !e.target.checked } : p) }))} label="Não" />
                                                    </div>
                                                </ListGroup.Item>)}
                                        </ListGroup>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </>}
                <hr />
                <h4 className="mb-3">
                    Anexos
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        <Table responsive hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nome do Arquivo</th>
                                    <th>Descrição</th>
                                    <th style={{ width: 1 }} className="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Anexos.map((anexo, key) =>
                                    <tr key={key}>
                                        <td>{anexo.nome}</td>
                                        <td>{anexo.descricao}</td>
                                        <td className="text-nowrap text-center">
                                            <Button variant="primary" size="sm" onClick={() => this.downloadAnexo(anexo)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Button>
                                            &nbsp;
                                            {this.context.usuario.Recursos.find(r => r === "Excluir Anexo") &&
                                                <Button variant="danger" size="sm" onClick={() => this.deleteAnexo(anexo)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>}
                                        </td>
                                    </tr>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        <Button size="sm" variant="success" className="form-control" onClick={() => this.uploadAnexoRef.current.click()}>
                                            <FontAwesomeIcon icon={faPlus} /> Adicionar Arquivo
                                        </Button>
                                        <input type="file" multiple className="d-none" ref={this.uploadAnexoRef} onChange={e => this.onUpload(e)} />
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Boletos
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        {(!this.state.Boletos.length && "Nenhum boleto registrado.") || <Table responsive hover size="sm">
                            <thead>
                                <tr>
                                    <th>Taxa</th>
                                    <th>Valor&nbsp;(R$)</th>
                                    <th>Vencimento</th>
                                    <th>Pagamento</th>
                                    <th>Status</th>
                                    <th style={{ width: 1 }} className="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Boletos.map((boleto, key) =>
                                    <tr key={key}>
                                        <td style={{ verticalAlign: 'middle' }}>{boleto.Taxa?.descricao}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{boleto.valor.toFixed(2).replace('.', ',')}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{boleto.data_vencimento ? moment(boleto.data_vencimento).format("DD/MM/YYYY") : "Pendente"}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{boleto.data_pagamento ? moment(boleto.data_pagamento).format("DD/MM/YYYY") : "Pendente"}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{boleto.status}</td>
                                        <td className="text-nowrap text-center" style={{ verticalAlign: 'middle' }}>
                                            {(boleto.status === "Vencido" && [this.state.BoletoEmissaoId, this.state.BoletoAnaliseId, this.state.BoletoVistoriaId].indexOf(boleto.id) > -1 && <>
                                                <Button className="me-1" variant="success" size="sm" title="Renovar" onClick={() => this.renovarBoleto(boleto)}>
                                                    <FontAwesomeIcon icon={Icons.faRefresh} />
                                                </Button>
                                            </>) || (boleto.status === "Aberto" && <>
                                                <Button className="me-1" variant="primary" size="sm" title="Download" onClick={() => this.downloadBoleto(boleto)}>
                                                    <FontAwesomeIcon icon={Icons.faBarcode} />
                                                </Button>
                                                {this.context.usuario.Recursos.find(r => r === "Isentar Boleto") && <Button className="me-1 text-white" variant="success" size="sm" title="Isentar" onClick={() => this.isentarBoleto(boleto)}>
                                                    <FontAwesomeIcon icon={Icons.faMoneyBill1Wave} />
                                                </Button>}
                                                {this.context.usuario.Recursos.find(r => r === "Confirmar Boleto") && <Button className="me-1" variant="warning" size="sm" title="Confirmar" onClick={() => this.confirmarBoleto(boleto)}>
                                                    <FontAwesomeIcon icon={Icons.faMoneyCheckDollar} />
                                                </Button>}
                                                {this.context.usuario.Recursos.find(r => r === "Descontar Boleto") && [this.state.BoletoAnaliseId, this.state.BoletoVistoriaId, this.state.BoletoEmissaoId].indexOf(boleto.id) > -1 && <Button className="me-1" variant="dark" size="sm" title="Descontar" onClick={() => this.descontarBoleto(boleto)}>
                                                    <FontAwesomeIcon icon={Icons.faPercentage} />
                                                </Button>}
                                                {this.context.usuario.Recursos.find(r => r === "Excluir Boleto") && <Button className="me-1" variant="danger" size="sm" title="Excluir" onClick={() => this.deleteBoleto(boleto)}>
                                                    <FontAwesomeIcon icon={Icons.faTrash} />
                                                </Button>}
                                            </>) || ((boleto.status === "Pago" || boleto.status === "Isento") && this.context.usuario.Recursos.find(r => r === "Complementar Boleto") && <>
                                                <Button className="me-1" variant="info" size="sm" title="Complementar" onClick={() => this.complementarBoleto(boleto)}>
                                                    <FontAwesomeIcon icon={Icons.faPlus} />
                                                </Button>
                                            </>) || "Sem Ações"}
                                        </td>
                                    </tr>)}
                            </tbody>
                        </Table>}
                    </Col>
                </Row>
                <hr />
                <h4 className="mb-3">
                    Atribuições
                </h4>
                <Row>
                    <Col lg={12} className="mb-1">
                        {(!this.state.Atribuicoes.length && "Nenhuma atribuição registrada.") || <Accordion>
                            {this.state.Atribuicoes.map((atribuicao, key) =>
                                <Accordion.Item key={key} eventKey={key + 1}>
                                    <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div>
                                                {atribuicao.Usuario?.nome} - {atribuicao.Funcao?.descricao}<br />
                                                Agendamento: {moment(atribuicao.datahora_agendamento).format("DD/MM/YYYY HH:mm")} -
                                                Execução: {atribuicao.datahora_execucao ? moment(atribuicao.datahora_execucao).format("DD/MM/YYYY HH:mm") : "Pendente"}
                                            </div>
                                            <Badge
                                                className={`me-1 bg-${{
                                                    Pendente: "info",
                                                    Atrasado: "warning",
                                                    Cancelado: "secondary",
                                                    Aprovado: "success",
                                                    Reprovado: "danger",
                                                }[atribuicao.status]}`}>
                                                {atribuicao.status}
                                            </Badge>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {(!atribuicao.Pendencias.length && <div className="card card-body bg-light mb-2">Nenhuma pendência registrada.</div>) || <Table className="mb-2">
                                            <thead>
                                                <tr>
                                                    <th>Exigência</th>
                                                    <th style={{ width: "1%" }}>Quantidade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {atribuicao.Pendencias.map((pendencia, key) =>
                                                    <tr key={key}>
                                                        <td>
                                                            <b>{pendencia.Exigencia?.descricao}</b><br />
                                                            <small>{pendencia.observacao}</small>
                                                        </td>
                                                        <td>{pendencia.quantidade}</td>
                                                    </tr>)}
                                            </tbody>
                                        </Table>}
                                        {atribuicao.Anexos.length ? <Table responsive hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Nome do Arquivo</th>
                                                    <th>Descrição</th>
                                                    <th style={{ width: 1 }} className="text-center">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {atribuicao.Anexos.map((anexo, key) =>
                                                    <tr key={key}>
                                                        <td>{anexo.nome}</td>
                                                        <td>{anexo.descricao}</td>
                                                        <td className="text-nowrap text-center">
                                                            <Button variant="primary" size="sm" onClick={() => this.downloadAnexo(anexo)}>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </Button>
                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </Table> : null}
                                        {atribuicao.observacao && <Form.Group>
                                            <Form.Label><b>Observação</b></Form.Label>
                                            <Form.Control as="textarea" value={atribuicao.observacao} disabled readOnly />
                                        </Form.Group>}
                                    </Accordion.Body>
                                </Accordion.Item>)}
                        </Accordion>}
                    </Col>
                </Row>
                {this.state.tipo === "Licenciamento" && <>
                    <hr />
                    <h4 className="mb-3">
                        Dados do Licenciamento
                    </h4>
                    <Row>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Necessita de Análise de Projeto?</b></Form.Label>
                                <Form.Control disabled value={this.state.analise_necessaria ? "Sim" : "Não"} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Análises Realizadas</b></Form.Label>
                                <Form.Control disabled value={this.state.analises_realizadas} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Projeto sob Análise</b></Form.Label>
                                {(this.state.AnexoAnaliseId &&
                                    <Button variant="primary" className="form-control" onClick={() => this.downloadAnexoAnalise()}>
                                        <FontAwesomeIcon icon={faDownload} /> Baixar Projeto
                                    </Button>)
                                    || <Button variant="info" className="form-control" disabled>
                                        Nenhum Projeto Apresentado
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Vínculo do Projetista</b></Form.Label>
                                <ReactSelect
                                    options={this.state.vinculos.filter(option => option.vinculo.Modalidade.projeto)}
                                    value={this.state.vinculos.find(option => option.value === this.state.VinculoResponsavelProjetoId)}
                                    onChange={option => this.setState({ VinculoResponsavelProjetoId: option?.value ?? null })}
                                    placeholder="Selecione uma vinculado..."
                                    noOptionsMessage={() => "Sem opções"}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Necessita de Vistoria?</b></Form.Label>
                                <Form.Control disabled value={this.state.vistoria_necessaria ? "Sim" : "Não"} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Vistorias Realizadas</b></Form.Label>
                                <Form.Control disabled value={this.state.vistorias_realizadas} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Dispensado de Ato Administrativo?</b></Form.Label>
                                <Form.Control disabled value={this.state.dispensa_necessaria ? "Sim" : "Não"} />
                            </Form.Group>
                        </Col>
                        <Col lg={8} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Vínculo do Responsável pela Instalação/Execução</b></Form.Label>
                                <ReactSelect
                                    options={this.state.vinculos.filter(option => option.vinculo.Modalidade.execucao)}
                                    value={this.state.vinculos.find(option => option.value === this.state.VinculoResponsavelExecucaoId)}
                                    onChange={option => this.setState({ VinculoResponsavelExecucaoId: option?.value ?? null })}
                                    placeholder="Selecione um vinculado..."
                                    noOptionsMessage={() => "Sem opções"}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Protocolo da Última Renovação</b></Form.Label>
                                <Form.Control disabled value={this.state.ProtocoloRenovacaoId?.toString().padStart(6, '0')} />
                            </Form.Group>
                        </Col>
                    </Row>
                </>}
                {(this.state.tipo === "Renovação de Licenciamento" || this.state.tipo === "Renovação de Credenciamento") && <>
                    <hr />
                    <h4 className="mb-3">
                        Dados da Renovação
                    </h4>
                    <Row>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Protocolo Original</b></Form.Label>
                                <Form.Control disabled value={this.state.ProtocoloOriginalId?.toString().padStart(6, '0')} />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-1">
                            <Form.Check required={!this.state.id} label={<div className="text-danger text-justify">
                                O usuário confirma a integral manutenção das medidas de segurança
                                contra incêndios e emergências e das das características consignadas no licenciamento/credenciamento original,
                                sendo assim dispensada a vistoria técnica, porém sujeita à fiscalização pelo Corpo de Bombeiros Militar do Pará
                                em qualquer tempo, conforme §1º do Art. 62 da Lei nº 9.234, de 24 de março de 2021.
                            </div>} />
                        </Col>
                    </Row>
                </>}
                {(this.state.tipo === "Credenciamento" || this.state.tipo === "Renovação de Credenciamento") && <>
                    <hr />
                    <h4 className="mb-3">
                        Dados do Credenciamento
                    </h4>
                    <Row>
                        <Col lg={12} className="mb-1">
                            <Form.Group>
                                <Form.Label className="required"><b>Modalidade de Credenciamento</b></Form.Label>
                                <ReactSelect
                                    options={this.state.modalidades.filter(option => option.tipo === this.state.Cadastro?.tipo)}
                                    value={this.state.modalidades.find(option => option.value === this.state.ModalidadeId)}
                                    onChange={option => this.setState({ ModalidadeId: option.value ?? null })}
                                    placeholder="Selecione uma modalidade..."
                                    noOptionsMessage={() => "Sem opções"}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        {this.state.Cadastro?.tipo === "Pessoa Jurídica" && <>
                            <Col lg={12} className="mb-1">
                                <Form.Group>
                                    <Form.Label className="required"><b>Responsável Técnico Vinculado</b></Form.Label>
                                    <ReactSelect
                                        options={this.state.vinculos.filter(option => option.vinculo.Modalidade.execucao)}
                                        isOptionDisabled={option => !option.vinculo.Modalidade.execucao && option.vinculo.datahora_confirmacao && !option.vinculo.datahora_revogacao}
                                        value={this.state.vinculos.find(option => option.value === this.state.VinculoResponsavelCredenciamentoId)}
                                        onChange={option => this.setState({ VinculoResponsavelCredenciamentoId: option.value ?? null })}
                                        placeholder="Selecione um vinculado..."
                                        noOptionsMessage={() => "Sem opções"}
                                    />
                                </Form.Group>
                            </Col>
                            {this.state.modalidades.find(option => option.value === this.state.ModalidadeId)?.instrucao && <Col lg={12} className="mb-1">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Intrutores</th>
                                            <th style={{ width: '1%' }}>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.VinculosInstrutores.map((VinculoInstrutorId, key) => {
                                            const option = this.state.vinculos.find(v => v.value === VinculoInstrutorId);
                                            return option && <tr key={key}>
                                                <td>{option.label}</td>
                                                <td>
                                                    <Button title="Excluir Vínculo" variant="danger" onClick={() => this.setState(state => ({ VinculosInstrutores: state.VinculosInstrutores.filter(VinculoInstrutorId => VinculoInstrutorId !== option.value) }))}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="text-danger small" colSpan={2}><b>Selecione um vínculo e clique em adicionar</b></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Form.Select value={this.state.VinculoInstrutorId} onChange={event => this.setState({ VinculoInstrutorId: event.target.value })}>
                                                    <option value="">Selecione um vínculo</option>
                                                    {this.state.vinculos.filter(option => option.vinculo.Modalidade.instrucao && option.vinculo.datahora_confirmacao && !option.vinculo.datahora_revogacao).map((option, key) =>
                                                        <option key={key} value={option.value}>{option.label}</option>
                                                    )}
                                                </Form.Select>
                                            </td>
                                            <td>
                                                <Button title="Adicionar Vínculo" disabled={!this.state.VinculoInstrutorId} variant="success" onClick={() => this.state.VinculoInstrutorId && this.setState(state => ({ VinculosInstrutores: [...state.VinculosInstrutores, parseInt(state.VinculoInstrutorId)] }))}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>}
                        </>}
                    </Row>
                </>}
                {this.state.tipo === "Anotação" && <>
                    <hr />
                    <h4 className="mb-3">
                        Dados da Anotação
                    </h4>
                    <Row>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label className={!this.state.CursoId ? "force-required" : ""}><b>Curso/Treinamento</b></Form.Label>
                                <ReactSelect
                                    options={this.state.cursos}
                                    value={this.state.cursos.find(option => option.value === this.state.CursoId)}
                                    onChange={option => this.setState({ CursoId: option.value ?? null })}
                                    placeholder="Selecione um curso..."
                                    noOptionsMessage={() => "Sem opções"}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label className={!this.state.VinculoResponsavelAnotacaoId ? "force-required" : ""}><b>Responsável Técnico</b></Form.Label>
                                <ReactSelect
                                    options={this.state.vinculos.filter(option => this.state.VinculoResponsavelAnotacaoId === option.value || (option.vinculo.Modalidade.instrucao && option.vinculo.datahora_confirmacao && !option.vinculo.datahora_revogacao))}
                                    value={this.state.vinculos.find(option => option.value === this.state.VinculoResponsavelAnotacaoId)}
                                    onChange={option => this.setState({ VinculoResponsavelAnotacaoId: option.value ?? null })}
                                    placeholder="Selecione um vinculado..."
                                    noOptionsMessage={() => "Sem opções"}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Form.Group>
                                <Form.Label><b>Data de Início</b></Form.Label>
                                <Form.Control required type="date" value={this.state.data_inicio ?? ""} onChange={e => this.setState({ data_inicio: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Form.Group>
                                <Form.Label><b>Data de Conclusão</b></Form.Label>
                                <Form.Control required type="date" value={this.state.data_fim ?? ""} onChange={e => this.setState({ data_fim: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Form.Group>
                                <Form.Label><b>Carga Horária</b></Form.Label>
                                <Form.Control required type="number" min="0" step="1" value={this.state.carga_horaria ?? ""} onChange={e => this.setState({ carga_horaria: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-3">
                            <Form.Group>
                                <Form.Label><b>Nota Mínima (0-100)</b></Form.Label>
                                <Form.Control required type="number" min="0" max="100" step="1" value={this.state.nota_minima ?? ""} onChange={e => this.setState({ nota_minima: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Form.Group>
                                <Form.Label><b>Local de Treinamento</b></Form.Label>
                                <Form.Control required rows={2} as="textarea" value={this.state.local_treinamento} onChange={e => this.setState({ local_treinamento: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th><Form.Label className={!this.state.VinculosInstrutores.length ? "force-required" : ""}>Instrutores</Form.Label></th>
                                        <th style={{ width: '1%' }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.VinculosInstrutores.map((VinculoInstrutorId, key) => {
                                        const option = this.state.vinculos.find(v => v.value === VinculoInstrutorId);
                                        return option && <tr key={key}>
                                            <td>{option.label}</td>
                                            <td>
                                                <Button title="Excluir Vínculo" variant="danger" onClick={() => this.setState(state => ({ VinculosInstrutores: state.VinculosInstrutores.filter(VinculoInstrutorId => VinculoInstrutorId !== option.value) }))}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="text-danger small" colSpan={2}><b>Selecione um vínculo e clique em adicionar</b></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Select value={this.state.VinculoInstrutorId} onChange={event => this.setState({ VinculoInstrutorId: event.target.value })}>
                                                <option value="">Selecione um vínculo</option>
                                                {this.state.vinculos.filter(option => option.vinculo.Modalidade.instrucao && option.vinculo.datahora_confirmacao && !option.vinculo.datahora_revogacao).map((option, key) =>
                                                    <option key={key} value={option.value}>{option.label}</option>
                                                )}
                                            </Form.Select>
                                        </td>
                                        <td>
                                            <Button title="Adicionar Vínculo" disabled={!this.state.VinculoInstrutorId} variant="success" onClick={() => this.state.VinculoInstrutorId && this.setState(state => ({ VinculosInstrutores: [...state.VinculosInstrutores, parseInt(state.VinculoInstrutorId)] }))}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                        <Col lg={12} className="mb-1">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Aluno</th>
                                        <th>CPF</th>
                                        <th>Nota Final (0-100)</th>
                                        <th>Status</th>
                                        <th style={{ width: 1 }} className="text-center">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.Alunos.map((aluno, key) =>
                                        <tr key={key}>
                                            <td>
                                                <Form.Control required placeholder="Nome do Aluno" value={aluno.nome} onChange={e => this.setState(state => ({ Alunos: state.Alunos.map(a => a === aluno ? { ...a, nome: e.target.value } : a) }))} />
                                            </td>
                                            <td>
                                                <InputMask mask="999.999.999-99" maskChar={'_'} className="form-control" placeholder="CPF do Aluno" value={aluno.cpf} onChange={e => this.setState(state => ({ Alunos: state.Alunos.map(a => a === aluno ? { ...a, cpf: e.target.value } : a) }))} />
                                            </td>
                                            <td>
                                                <Form.Control type="number" min="0" max="100" step="1" required placeholder="Nota do Aluno" value={aluno.nota_final} onChange={e => this.setState(state => ({ Alunos: state.Alunos.map(a => a === aluno ? { ...a, nota_final: e.target.value } : a) }))} />
                                            </td>
                                            <td>
                                                <Form.Select required value={aluno.status} onChange={e => this.setState(state => ({ Alunos: state.Alunos.map(a => a === aluno ? { ...a, status: e.target.value } : a) }))}>
                                                    <option value="">Selecione um status</option>
                                                    <option>Aprovado</option>
                                                    <option>Reprovado</option>
                                                </Form.Select>
                                            </td>
                                            <td className="text-nowrap text-center">
                                                <Button variant="danger" size="sm" onClick={() => this.setState(state => ({ Alunos: state.Alunos.filter(a => a !== aluno) }))}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5}>
                                            <Button disabled={this.state.Alunos.length >= 30} variant="success" className="form-control" onClick={() => this.setState(state => ({ Alunos: [...state.Alunos, {}] }))}>
                                                <FontAwesomeIcon icon={faPlus} /> Adicionar Aluno
                                            </Button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </>}
                {(this.state.tipo === "Fiscalização" || this.state.tipo === "Autuação" || (this.state.tipo === "Licenciamento" && this.state.data_advertencia) ) && <>
                    <hr />
                    <h4 className="mb-3">
                        Infrações Registradas
                    </h4>
                    <Row>
                        <Col lg={12} className="mb-1">
                            {(!this.state.Infracoes.length && "Nenhuma infração registrada.") || <Table responsive hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th style={{ width: '1%' }}>Gravidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.Infracoes.map((InfracaoId, key) => {
                                        const option = this.state.infracoes.find(option => option.value === InfracaoId);
                                        return option && <tr key={key}>
                                            <td>{option.label}</td>
                                            <td>{option.gravidade}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>}
                        </Col>
                    </Row>
                    <hr />
                    <h4 className="mb-3">
                        Certificados Cassados
                    </h4>
                    <Row>
                        <Col lg={12} className="mb-1">
                            {(!this.state.CertificadosCassados.length && "Nenhum anexo registrado.") || <Table responsive hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th style={{ width: '1%' }}>Emisão</th>
                                        <th style={{ width: '1%' }}>Validade</th>
                                        <th style={{ width: '1%' }}>Cassação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.CertificadosCassados.map((certificado, key) =>
                                        <tr key={key}>
                                            <td>{certificado.tipo}</td>
                                            <td>{moment(certificado.data_emissao).format("DD/MM/YYYY")}</td>
                                            <td>{certificado.data_validade ? moment(certificado.data_validade).format("DD/MM/YYYY") : "Indefinida"}</td>
                                            <td>{certificado.data_cassacao ? moment(certificado.data_cassacao).format("DD/MM/YYYY") : "Indefinida"}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>}
                        </Col>
                    </Row>
                </>}
                {this.state.tipo === "Fiscalização" && <>
                    <hr />
                    <h4 className="mb-3">
                        Dados da Fiscalização
                    </h4>
                    <Row>
                        <Col lg={12} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Protocolo de Autuação</b></Form.Label>
                                {(this.state.ProtocoloAutuacaoId &&
                                    <Button variant="primary" className="form-control" onClick={() => this.gotoAutuacao()}>
                                        <FontAwesomeIcon icon={faDownload} /> Nª {this.state.ProtocoloAutuacaoId} - Ir Para Autuação
                                    </Button>)
                                    || <div>Autuação não instaurada.</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                </>}
                {(this.state.data_advertencia || this.state.data_multasimples) && <>
                    <hr />
                    <h4 className="mb-3">
                        Dados da Autuação
                    </h4>
                    <h5 className="mb-3 text-danger">
                        Valor de Referência da Multa: R$ {this.state.valor_multa.toFixed(2).toString().replace('.', ',')}
                    </h5>
                    <Row>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Protocolo de Fiscalização</b></Form.Label>
                                {(this.state.ProtocoloFiscalizacaoId &&
                                    <Button variant="warning" className="form-control" onClick={() => this.gotoFiscalizacao()}>
                                        <FontAwesomeIcon icon={Icons.faUndo} /> {this.state.ProtocoloFiscalizacaoId.toString().padStart(6, '0')} - Ir Para Fiscalização
                                    </Button>)
                                    ||
                                    <Button variant="warning" className="form-control" disabled>
                                        Fiscalização não indicada
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Protocolo Fiscalizado</b></Form.Label>
                                {(this.state.ProtocoloOriginalId &&
                                    <Button variant="warning" className="form-control" onClick={() => this.gotoFiscalizado()}>
                                        <FontAwesomeIcon icon={Icons.faUndo} /> {this.state.ProtocoloOriginalId.toString().padStart(6, '0')} - Ir Para Protocolo Original
                                    </Button>)
                                    ||
                                    <Button variant="warning" className="form-control" disabled>
                                        Protocolo não indicado
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data/Hora da Autuação</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_entrada ? moment(this.state.datahora_entrada).format("LLL") : "Não reconhecido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Ciência da Advertência</b></Form.Label>
                                <Form.Control disabled value={this.state.data_advertencia ? moment(this.state.data_advertencia).format("DD/MM/YYYY") : "Não reconhecida"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Recurso Preliminar</b></Form.Label>
                                {(this.state.AnexoRecursoPreliminarId &&
                                    <Button variant="primary" className="form-control" onClick={() => this.downloadAnexoRecursoPreliminar()}>
                                        <FontAwesomeIcon icon={faDownload} /> Baixar Recurso Preliminar
                                    </Button>)
                                    || <Button variant="info" className="form-control" disabled>
                                        Nenhum Recurso Preliminar Apresentado
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data do Recurso</b></Form.Label>
                                <Form.Control disabled value={this.state.data_recursopreliminar ? moment(this.state.data_recursopreliminar).format("DD/MM/YYYY") : "Não apresentado"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Resultado do Julgamento</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoJulgamentoPreliminarId ? this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoJulgamentoPreliminarId)?.status : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Pedido de Prorrogação</b></Form.Label>
                                {(this.state.AnexoPedidoProrrogacaoId &&
                                    <Button variant="primary" className="form-control" onClick={() => this.downloadAnexoPedidoProrrogacao()}>
                                        <FontAwesomeIcon icon={faDownload} /> Baixar Pedido de Prorrogação
                                    </Button>)
                                    || <Button variant="info" className="form-control" disabled>
                                        Nenhum Pedido de Prorrogação Apresentado
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data do Pedido</b></Form.Label>
                                <Form.Control disabled value={this.state.data_pedidoprorrogacao ? moment(this.state.data_pedidoprorrogacao).format("DD/MM/YYYY") : "Não apresentado"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Resultado do Julgamento</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoJulgamentoProrrogacaoId ? this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoJulgamentoProrrogacaoId)?.status : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Declaração de Saneamento Preliminar</b></Form.Label>
                                <Form.Control disabled value={this.state.data_saneamentopreliminar ? moment(this.state.data_saneamentopreliminar).format("DD/MM/YYYY") : "Não declarado"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Vistoria Preliminar</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoVistoriaPreliminarId ? moment(this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoVistoriaPreliminarId)?.data_agendamento).format("DD/MM/YYYY") : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Resultado da Vistoria</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoVistoriaPreliminarId ? this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoVistoriaPreliminarId)?.status : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Multa Simples</b></Form.Label>
                                {(this.state.BoletoMultaSimplesId &&
                                    <Button variant="danger" className="form-control" onClick={() => this.downloadBoletoMultaSimples()}>
                                        <FontAwesomeIcon icon={Icons.faFileInvoiceDollar} /> Boleto de Multa Simples - {`R$ ${this.state.Boletos.find(b => b.id === this.state.BoletoMultaSimplesId)?.valor.toLocaleString('pt-br')}`}
                                    </Button>)
                                    || <Button variant="danger" className="form-control" disabled>
                                        Nenhuma Multa Emitida
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Emissão</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaSimplesId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaSimplesId)?.data_emissao).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Vencimento</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaSimplesId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaSimplesId)?.data_vencimento).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Recurso de Multa</b></Form.Label>
                                {(this.state.AnexoRecursoMultaId &&
                                    <Button variant="primary" className="form-control" onClick={() => this.downloadAnexoRecursoMulta()}>
                                        <FontAwesomeIcon icon={faDownload} /> Baixar Recurso de Multa
                                    </Button>)
                                    || <Button variant="info" className="form-control" disabled>
                                        Nenhum Recurso de Multa Apresentado
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data do Recurso</b></Form.Label>
                                <Form.Control disabled value={this.state.data_recursomulta ? moment(this.state.data_recursomulta).format("DD/MM/YYYY") : "Não apresentado"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Resultado do Julgamento</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoJulgamentoMultaId ? this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoJulgamentoMultaId)?.status : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Declaração de Saneamento Definitivo</b></Form.Label>
                                <Form.Control disabled value={this.state.data_saneamentodefinitivo ? moment(this.state.data_saneamentodefinitivo).format("DD/MM/YYYY") : "Não declarado"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Vistoria Definitiva</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoVistoriaDefinitivaId ? moment(this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoVistoriaDefinitivaId)?.data_agendamento).format("DD/MM/YYYY") : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Resultado da Constatação</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoVistoriaDefinitivaId ? this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoVistoriaDefinitivaId)?.status : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Multa Reduzida</b></Form.Label>
                                {(this.state.BoletoMultaReduzidaId &&
                                    <Button variant="danger" className="form-control" onClick={() => this.downloadBoletoMultaReduzida()}>
                                        <FontAwesomeIcon icon={Icons.faFileInvoiceDollar} /> Boleto de Multa Reduzida - {`R$ ${this.state.Boletos.find(b => b.id === this.state.BoletoMultaReduzidaId)?.valor.toLocaleString('pt-br')}`}
                                    </Button>)
                                    || <Button variant="danger" className="form-control" disabled>
                                        Nenhuma Multa Emitida
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Emissão</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaReduzidaId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaReduzidaId)?.data_emissao).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Vencimento</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaReduzidaId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaReduzidaId)?.data_vencimento).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Multa Complementar</b></Form.Label>
                                {(this.state.BoletoMultaComplementarId &&
                                    <Button variant="danger" className="form-control" onClick={() => this.downloadBoletoMultaComplementar()}>
                                        <FontAwesomeIcon icon={Icons.faFileInvoiceDollar} /> Boleto de Multa Complementar - {`R$ ${this.state.Boletos.find(b => b.id === this.state.BoletoMultaComplementarId)?.valor.toLocaleString('pt-br')}`}
                                    </Button>)
                                    || <Button variant="danger" className="form-control" disabled>
                                        Nenhuma Multa Emitida
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Emissão</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaComplementarId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaComplementarId)?.data_emissao).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Vencimento</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaComplementarId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaComplementarId)?.data_vencimento).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Pedido de TAACB</b></Form.Label>
                                {(this.state.AnexoPedidoTaacbId &&
                                    <Button variant="primary" className="form-control" onClick={() => this.downloadAnexoPedidoTaacb()}>
                                        <FontAwesomeIcon icon={faDownload} /> Baixar Pedido de TAACB
                                    </Button>)
                                    || <Button variant="info" className="form-control" disabled>
                                        Nenhum Pedido de TAACB Apresentado
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data do Pedido</b></Form.Label>
                                <Form.Control disabled value={this.state.data_pedidotaacb ? moment(this.state.data_pedidotaacb).format("DD/MM/YYYY") : "Não apresentado"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Resultado do Julgamento</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoJulgamentoTaacbId ? this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoJulgamentoTaacbId)?.status : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Multa de TAACB</b></Form.Label>
                                {(this.state.BoletoMultaTaacbId &&
                                    <Button variant="danger" className="form-control" onClick={() => this.downloadBoletoMultaTaacb()}>
                                        <FontAwesomeIcon icon={Icons.faFileInvoiceDollar} /> Boleto de Multa de TAACB - {`R$ ${this.state.Boletos.find(b => b.id === this.state.BoletoMultaTaacbId)?.valor.toLocaleString('pt-br')}`}
                                    </Button>)
                                    || <Button variant="danger" className="form-control" disabled>
                                        Nenhuma Multa Emitida
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Emissão</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaTaacbId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaTaacbId)?.data_emissao).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Vencimento</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaTaacbId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaTaacbId)?.data_vencimento).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Vistoria Sancionatória</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoVistoriaSancionatoriaId ? moment(this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoVistoriaSancionatoriaId)?.data_agendamento).format("DD/MM/YYYY") : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={9} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Resultado da Constatação</b></Form.Label>
                                <Form.Control disabled value={this.state.AtribuicaoVistoriaSancionatoriaId ? this.state.Atribuicoes.find(a => a.id === this.state.AtribuicaoVistoriaSancionatoriaId)?.status : "Sem Agendamento"} />
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Multa Dobrada</b></Form.Label>
                                {(this.state.BoletoMultaDobradaId &&
                                    <Button variant="danger" className="form-control" onClick={() => this.downloadBoletoMultaDobrada()}>
                                        <FontAwesomeIcon icon={Icons.faFileInvoiceDollar} /> Boleto de Multa Dobrada - {`R$ ${this.state.Boletos.find(b => b.id === this.state.BoletoMultaDobradaId)?.valor.toLocaleString('pt-br')}`}
                                    </Button>)
                                    || <Button variant="danger" className="form-control" disabled>
                                        Nenhuma Multa Emitida
                                    </Button>}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Emissão</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaDobradaId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaDobradaId)?.data_emissao).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data de Vencimento</b></Form.Label>
                                <Form.Control disabled value={this.state.BoletoMultaDobradaId ? moment(this.state.Boletos.find(b => b.id === this.state.BoletoMultaDobradaId)?.data_vencimento).format("DD/MM/YYYY") : "Não emitido"} />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Inscrição em Dívida Ativa</b></Form.Label>
                                <Form.Control className="bg-warning" disabled value={this.state.data_dividaativa ? moment(this.state.data_dividaativa).format("DD/MM/YYYY") : "Não inscrito em dívida ativa"} />
                            </Form.Group>
                        </Col>
                    </Row>
                </>}
                <hr />
                <h4 className="mb-3">
                    Observações
                </h4>
                <Row>
                    <Col lg={12} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Observações do Protocolo</b></Form.Label>
                            <Form.Control style={{ height: 200 }} as="textarea" value={this.state.observacao_protocolo} onChange={e => this.setState({ observacao_protocolo: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <h2 className="d-flex justify-content-end">
                    <Button variant="secondary" className="ms-auto me-1" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={Icons.faFastBackward} /> Voltar
                    </Button>

                    {this.props.protocolo.id && this.props.protocolo.status !== "Protocolo Arquivado" && this.context.usuario.Recursos.find(r => r === "Arquivar Protocolo") &&
                        <Button className="me-1" variant="danger" onClick={() => this.arquivar()} title="Arquivar Protocolo">
                            <FontAwesomeIcon icon={Icons.faArchive} /> Arquivar Protocolo
                        </Button>}

                    {this.props.protocolo.id && this.props.protocolo.status === "Protocolo Arquivado" && this.context.usuario.Recursos.find(r => r === "Desarquivar Protocolo") &&
                        <Button className="me-1" variant="info" onClick={() => this.desarquivar()} title="Desarquivar Protocolo">
                            <FontAwesomeIcon icon={Icons.faArchive} /> Desarquivar Protocolo
                        </Button>}

                    {this.props.protocolo.id && this.props.protocolo.CertificadoEmitidoId &&
                        <Button className="me-1" variant="success" onClick={() => this.downloadCertificado()} title="Baixar Certificado">
                            <FontAwesomeIcon icon={Icons.faDownload} /> Baixar Certificado
                        </Button>}

                    {((
                        !this.state.id && (this.context.usuario.classe === "cadastro" || this.context.usuario.Recursos.find(r => r === "Adicionar Protocolo"))
                    ) || this.context.usuario.Recursos.find(r => r === "Atualizar Protocolo")) &&
                        <Button type="submit" variant="primary">
                            <FontAwesomeIcon icon={Icons.faSave} /> Gravar
                        </Button>}
                </h2>
            </Form >
        </Container >;
    }
}

export default ProtocoloForm;